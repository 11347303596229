// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-response-js": () => import("./../../../src/pages/covid-response.js" /* webpackChunkName: "component---src-pages-covid-response-js" */),
  "component---src-pages-home-cover-plans-checkout-js": () => import("./../../../src/pages/home-cover-plans/checkout.js" /* webpackChunkName: "component---src-pages-home-cover-plans-checkout-js" */),
  "component---src-pages-home-cover-plans-js": () => import("./../../../src/pages/home-cover-plans.js" /* webpackChunkName: "component---src-pages-home-cover-plans-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-boilers-js": () => import("./../../../src/pages/new-boilers.js" /* webpackChunkName: "component---src-pages-new-boilers-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quote-checkout-js": () => import("./../../../src/pages/quote/checkout.js" /* webpackChunkName: "component---src-pages-quote-checkout-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-quote-result-js": () => import("./../../../src/pages/quote/result.js" /* webpackChunkName: "component---src-pages-quote-result-js" */),
  "component---src-pages-request-a-callback-js": () => import("./../../../src/pages/request-a-callback.js" /* webpackChunkName: "component---src-pages-request-a-callback-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-boiler-js": () => import("./../../../src/templates/boiler.js" /* webpackChunkName: "component---src-templates-boiler-js" */),
  "component---src-templates-local-service-js": () => import("./../../../src/templates/local-service.js" /* webpackChunkName: "component---src-templates-local-service-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-sub-service-js": () => import("./../../../src/templates/sub-service.js" /* webpackChunkName: "component---src-templates-sub-service-js" */)
}

